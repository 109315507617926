import React, { useEffect, useState } from 'react';
import '../styles/Profil.css';
import jwt_decode from 'jwt-decode';
import ToggleButton from './ToggleButton';


function Profil({ updateUsername }) {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUserData, setEditedUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  
  const handleEdit = () => {
    setIsEditing(true);
    setEditedUserData(userData);
  };

  const handleSave = () => {
    fetch(`/api/user/${userId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editedUserData),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsEditing(false);
          setUserData(editedUserData);
          // Appelez la fonction pour mettre à jour `username` dans le composant `Header`
          updateUsername(editedUserData.username);
        } else {
          throw new Error('Erreur lors de la mise à jour des données de l\'utilisateur');
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour des données de l\'utilisateur:', error);
      });
  };

   useEffect(() => {
    const storedToken = localStorage.getItem('authToken');

    if (!storedToken) {
      console.error('Utilisateur non authentifié');
      return;
    }

    const decodedToken = jwt_decode(storedToken);
    const decodedUserId = decodedToken.id;

    setUserId(decodedUserId);
    setToken(storedToken);

    fetch(`/api/user/${decodedUserId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${storedToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Erreur lors de la récupération des données de l\'utilisateur');
        }
      })
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des données de l\'utilisateur:', error);
      });
  }, [token, userId]);


  return (
    <>    
        
      <div className="backgroundPropos100">
        <div className="propos-item1">
          <ToggleButton
            title="Profil : modification"
            text={
              <div>
                {userData && (
                  <div className='Profil-flex'>
                    <p className="text-static">Nom d'utilisateur: {isEditing ? (
                      <span className="text-dynamic">
                        <input
                          type="text"
                          value={editedUserData.username}
                          onChange={(e) => setEditedUserData({ ...editedUserData, username: e.target.value })}
                        />
                      </span>
                    ) : (
                      <span className="text-dynamic">
                        {userData.username}
                      </span>
                    )
                    
                    }</p>
                    <p className="text-static">ID & Manager ID: {isEditing ? (
                      <span className="text-dynamic">
                        <input
                          type="text"
                          value={editedUserData.id}
                          onChange={(e) => setEditedUserData({ ...editedUserData, id: e.target.value })}
                        />
                      </span>
                    ) : (
                      <span className="text-dynamic">
                        {userData.id}
                      </span>
                    )
                    
                    }</p>
                      <p className="text-static">Nom: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.name}
                  onChange={(e) => setEditedUserData({ ...editedUserData, name: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.name}
                </span>
              )}</p>
              <p className="text-static">Adresse: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.address}
                  onChange={(e) => setEditedUserData({ ...editedUserData, address: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.address}
                </span>
              )}</p>
              <p className="text-static">Code postal: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.postalCode}
                  onChange={(e) => setEditedUserData({ ...editedUserData, postalCode: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.postalCode}
                </span>
              )}</p>
              <p className="text-static">Ville: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.city}
                  onChange={(e) => setEditedUserData({ ...editedUserData, city: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.city}
                </span>
              )}</p>
              <p  className="text-static">Pays: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.country}
                  onChange={(e) => setEditedUserData({ ...editedUserData, country: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.country}
                </span>
              )}</p>
              <p className="text-static">Email: {isEditing ? (
                <span className="text-dynamic">
                <input
                  type="text"
                  value={editedUserData.email}
                  onChange={(e) => setEditedUserData({ ...editedUserData, email: e.target.value })}
                />
                </span>
              ) : (
                <span className="text-dynamic">
                {userData.email}
                </span>
              )}</p>
                    <div className="toggle-button-container">
                      {!isEditing ? (
                        <button className="toggle-button" onClick={handleEdit}>Modifier</button>
                      ) : (
                        <button className="toggle-button" onClick={handleSave}>Enregistrer</button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

export default Profil;