import React, { useEffect, useState } from 'react';
import '../styles/Footer.css'
import { Link } from 'react-router-dom';

function Footer() {
    const [serverTime, setServerTime] = useState(null);
   

    useEffect(() => {
        fetch('/get-last-elapsed-time')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const serverTime = parseFloat(data.lastElapsedTime); 
          setServerTime(serverTime);
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
      
      }, []);
      
    return (
        <footer>
        <div className='logoFooter'>Share & Excellence</div>
        <div className='right'>© 2023 Share & Excellence. All rights reserved - Propriétaire : Vidal Bravo - Jandia Miguel, 2 rue Alphonse Daudet 66000 Perpignan - France
        / Hébergement - PlanetHoster 4416 Louis-B.-Mayer Laval, Québec Canada H7P 0G1</div>
        <div className='contactfooter'>
        <Link to='/contact'>
        <p className='contactez'>Contactez-nous</p>
            
                <i class="fa-regular fa-envelope"></i>
            </Link>
            </div>
            <div className='right'>Données Personnelles et Confidentialité</div>
            <div className='contactfooter'>
        <Link to='/confidentialDataPolicy'>
        <p className='contactez'>Politique de confidentialité et Politique de gestion des cookies</p>
            </Link>
            </div>
            <div className='right'>Share Excellence Copyright</div>
            <div className='version'>
                <p className='version-footer'>- La reproduction de la base de données est strictement interdite - {serverTime !== null ? (
        <p className='propulse'> <span className='propulse'>Build 2 - V.3.0.1 - preRelease - Propulsée en : {serverTime} ms</span></p>
      ) : (
        <p>Chargement...</p>
      )} - Tous droits réservés - </p>
            </div>
            <Link to='/propos'><button type="button">A Propos</button></Link>
                                <Link to='/conditionGenerales'><button type="button">Condition Generale</button></Link>
        </footer>
    );

}

export default Footer