import '../styles/Header.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Profil from '../components/Profil';
import Carousel from '../components/Carousel';
import Carou1 from '../components/Carou1';

// Importez vos images statiques ici
import ImageBank from '../image/ImageBank.webp';
import ImageBankMobile from '../image/ImageBankMobile.webp';
import comptable from '../image/comptable.webp';
import comptableMobile from '../image/comptableMobile.webp';
import industrie from '../image/industrie.webp';
import industrieMobile from '../image/industrieMobile.webp';
import shareE1 from '../image/shareE1.webp';
import shareE1Mobile from '../image/shareE1Mobile.webp';
import logo from '../image/logo.png';

function Header() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [isShowing, setIsShowing] = useState(false);
  const [username, setUsername] = useState(null);
  const [query, setQuery] = useState('');  // Ajouté pour la recherche


  const navigate = useNavigate();

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= 992);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
// Search bar
const handleSearch = (e) => {
  e.preventDefault();
  navigate(`/search?query=${query}`); // Redirection vers la page de résultats de recherche
};

 // Vérifiez si un token d'authentification est présent dans le localStorage
 const authToken = localStorage.getItem('authToken');
 console.log('Token récupéré du localStorage:', authToken);
 const isAuthenticated = authToken !== null;

 // Fonction pour gérer la déconnexion
 const handleLogout = () => {
     // Supprimez le token d'authentification du localStorage
     localStorage.removeItem('authToken');
 };

 // Fonction pour mettre à jour username depuis le composant Profil
const updateUsername = (newUsername) => {
 setUsername(newUsername);
};

 useEffect(() => {
   // Si l'utilisateur est authentifié, extrayez le nom d'utilisateur du token
   if (isAuthenticated) {
     try {
       // Divisez le token en ses parties (header, payload, signature)
       const tokenParts = authToken.split('.');
       console.log('Token divisé en parties:', tokenParts);
       // Décodage de la partie payload (qui est encodée en base64)
       const decodedPayload = JSON.parse(atob(tokenParts[1]));
       console.log('Token décodé:', decodedPayload);
 
       // Vérifiez si le payload a été correctement décodé et s'il contient un nom d'utilisateur
       if (decodedPayload && decodedPayload.username) {
         setUsername(decodedPayload.username);
       }
     } catch (error) {
       console.error('Erreur lors du décodage du token :', error);
     }
   }
 }, [isAuthenticated, authToken]);
 

 useEffect(() => {
   // Rafraîchir la liste des fichiers après le téléchargement réussi
   const refreshInterval = setInterval(() => {
       // Vérifiez si l'utilisateur est authentifié
       if (isAuthenticated && username) {
           // Utilisez le username mis à jour ici
           console.log('L\'utilisateur ${username} est authentifié et le rafraîchissement automatique est en cours.');
       } else {
           console.log('L\'utilisateur n\'est pas authentifié, le rafraîchissement automatique est désactivé.');
       }
   }, 5000);
 
   return () => {
     clearInterval(refreshInterval);
   };
}, [isAuthenticated, username]); // username doit être inclus ici



const logout = async () => {
  const token = localStorage.getItem('authToken');
  try {
    await fetch('/logout', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    localStorage.removeItem('authToken');
    navigate('/');
    window.location.reload();
  } catch (error) {
    console.error('Erreur déconnexion:', error);
  }
};
  // Définissez le tableau statique des images pour le Carousel
  const carouselImages = [ImageBank, comptable, industrie, shareE1];
  const carouImages1 = [ImageBankMobile, comptableMobile, industrieMobile, shareE1Mobile];

  return (
    <>
      <header className='header'>


        
      <div className='cadreExcellence'>
       
      <Link to='/' className='link'>
  <span className='logo'>Share Excellence</span>
</Link>
                  <nav className="nav">
                    <Link to='/'><button type="button">Accueil</button></Link>
                    <Link to='/resultSearch'><button type="button">Rechercher</button></Link>
                    {isAuthenticated ? (
                  <button type="button" className='green' onClick={logout}>
                    {username ? `Bienvenue, ${username}, vous êtes maintenant connecté(e) - Déconnexion` : 'Bienvenue, vous êtes maintenant connecté(e) - Déconnexion'}
                  </button>
                ) : (
                  <Link to='/connect'><button type="button">Login membre</button></Link>
                )}
                   
                  </nav>
               
                  <Link to='/' className='linklogo'>
    <img src={logo} alt="Logo Share Excellence" className='logo-image' />
  </Link>
  <div className='dessin10'>

       
          <p className="phone">
            <a href="tel:+33649880958" className='tel'>Appeler Du lundi au vendredi de 9h à 19h</a>
          </p>
          
        </div> 
        
        </div>

        
          
        <div className='monMurTitle1'>
          <span className='accueil'>« Plateforme de création de contenu et de partage »</span>
          <br/>« Auteurs, Experts, Enseignants, formateurs, collaborateurs ou étudiants : Etablissez votre réseau - Etendez-le... »
        </div>
        {isDesktop ? (
          <Carousel slides={carouselImages} />
        ) : (
          <Carou1 diapo={carouImages1} />
        )}
        
       

      </header>
      {isAuthenticated && <Profil className='profil-header' />}
    </>
  );
}

export default Header;